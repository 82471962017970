import { get, set } from 'solid-utils/access';
import { createSignal, Show, useContext } from 'solid-js';
import { createAsync } from '@solidjs/router';

import { TaskViewModeType } from 'shared/network/schema';

import { postViewMode, updateViewMode } from '#/view-modes/save/network';
import { ViewMode } from '#/view-modes/model';
import { fetchViewModes } from '#/view-modes/list/network';
import { deleteViewMode } from '#/view-modes/delete/network';

import { ClientUser } from '../users.adapter';
import { ProjectContext } from '../project.context';

import TextArea from 'ui/elements/text-area';
import { MainButton } from 'shared/ui/telegram';
import List from 'shared/ui/list';
import Checkbox, { CheckboxStyle } from 'shared/ui/checkbox';


export default function FiltersPage() {
  const [project] = useContext(ProjectContext);
  const title = createSignal('');
  const query = createSignal('');
  const isPublic = createSignal(true);
  const id = createSignal('');
  let author = {};

  const [shouldRefresh, refresh] = createSignal(null, { equals: () => false });

  const projectViewModes = createAsync(() => (
    shouldRefresh(),
    fetchViewModes(project.latest.id)
  ), { initialValue: [] });

  return <main class="p-4">
    <p>project id: {project.latest.id}</p>
    <hr />

    <p>edit view modes:</p>
    <List refactor each={projectViewModes()}>
      {mode => <List.Item
        onClick={() => {
          author = mode.author;
          set(id, mode.id);
          set(query, mode.query);
          set(isPublic, mode.type === TaskViewModeType.Public);
          set(title, mode.title);
        }}
        right={<span class="c-text-negative!" onClick={() => {
          set(id, '');
          deleteViewMode(mode.id)
            .then(() => refresh(null));
        }}>X</span>}
      >
        {mode.title}
        <span class="c-text-secondary">
          &nbsp;by @{mode.author.userName ?? mode.author.userId}
        </span>
      </List.Item>}
    </List>

    <hr />
    <p>
      id: {get(id)}
      <Show when={get(id)}>
        <span class="c-text-negative!" onClick={() => set(id, '')}> X</span>
      </Show>
    </p>
    <hr />
    <p>title:</p>
    <TextArea model={title} class="bg-primary rounded-2 p-2" />
    <hr />
    <p>query:</p>
    <TextArea model={query} class="bg-primary rounded-2 p-2" />
    <hr />
    <p>is public: <Checkbox iconStyle={CheckboxStyle.Telegram} class="inline-block" model={isPublic} /></p>

    <MainButton
      onClick={() => {
        if (!get(id))
          return postViewMode({
            author,
            query: query[0](),
            projectId: project.latest.id,
            title: title[0](),
            type: isPublic[0]() ? TaskViewModeType.Public : TaskViewModeType.Private,
          });

        updateViewMode(get(id), {
          id: get(id),
          author,
          query: query[0](),
          projectId: project.latest.id,
          title: title[0](),
          type: isPublic[0]() ? TaskViewModeType.Public : TaskViewModeType.Private,
        });
      }}
      text={get(id) ? 'Update' : 'Add'}
    />
  </main>;
}